
import { Injectable } from '@angular/core';
import { LoggerService, PROPERTY_CONSTANTS } from '@nationwide/dgs-internet-servicing-policy-common';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IframeQueueService } from './iframe-queue.service';
import jwtDecode from 'jwt-decode';

export interface AuthorizeConfigBase {
    client_id: string;
    nonce: string;
    redirect_uri: string;
    response_type: string;
    scope: string;
    realm: string;
    state: string;
    message_id?: string;
    ttl?: number;
    auth_id_guid?: string;
}

export interface CustomerSearchConfig {
    auth_method: 'customer-search';
    realm: 'unidentified';
    /* eslint-disable @typescript-eslint/naming-convention */
    auth_id_agreementNumber: string;
    auth_id_postalCode: string;
    auth_id_birthDate: string;
    auth_id_lastName: string;
    /* eslint-enable @typescript-eslint/naming-convention */
}

export type CustomerSearchParams = Partial<AuthorizeConfigBase> & Partial<CustomerSearchConfig>;

export interface PingConfig {
    auth_method: string;
    realm: string;
}

export type PingParams = Partial<AuthorizeConfigBase> & Partial<PingConfig>;

@Injectable()
export class OauthClientService {
    constructor(
        private iframeQueueService: IframeQueueService,
        private LOGGER: LoggerService
    ) { }

    authorize(params: PingParams, updateToken = false): Observable<any> {
        let endpoint = environment.authorize.oauthEndpoint + this.toQueryString(params);
        if (updateToken) {
            this.LOGGER.info('Calling IAM to renew ping token');
            endpoint = `${environment.authorize.renewalTokenEndpoint}${window.location.origin}${environment.authorize.oauthRedirectHandlerPath}&timestamp=${Date.now()}`;
        } else {
            this.LOGGER.info('Calling Oauth for access token');
        }
        this.iframeQueueService.nextIn(endpoint);
        return this.iframeQueueService.listenForIframeQueueOutEvent().pipe(
            map((authServerResponse) => this.mapResponse(authServerResponse, updateToken)),
            tap((authMappedResponse) => this.setAccessTokenToStorage(authMappedResponse))
        );
    }

    private generateTokenExpiration(expiresIn: number): number {
        return Date.now() + expiresIn * PROPERTY_CONSTANTS.MILLISECONDS_IN_SECOND;
    }

    private mapResponse(authServerResponse: string | void, updateToken: boolean): any {
        if (authServerResponse) {
            this.iframeQueueService.clearQueueOut();
            const hashParams = authServerResponse.split('#')[1];
            let successful = false;

            let authParams = {};
            if (hashParams) {
                authParams = hashParams
                    .split('&')
                    .reduce((aggregatedParams, paramString) => {
                        const [key, value] = paramString.split('=');
                        aggregatedParams[key] = value;
                        return aggregatedParams;
                    }, {});
                successful = 'access_token' in authParams;
            } else if (typeof authServerResponse === 'string' && authServerResponse.includes('refreshResult=01')) {
                successful = true;
            }
            const authType = updateToken ? 'ping' : 'access';
            return { successful, authParams, authType };
        }
    }

    private setAccessTokenToStorage(authMappedResponse): void {
        if (authMappedResponse?.successful && authMappedResponse.authType === 'access') {
            sessionStorage.setItem('tokenDetails', `${authMappedResponse.authParams.access_token}, ${this.generateTokenExpiration(authMappedResponse.authParams.expires_in)}, ${authMappedResponse.authParams.expires_in}`);
            sessionStorage.setItem('access_token', authMappedResponse.authParams.access_token);
            sessionStorage.setItem('id_token', authMappedResponse.authParams.id_token);
            if (authMappedResponse.authParams.id_token) {
                const decodedToken = <any>(jwtDecode(authMappedResponse.authParams.id_token));
                const ecn = decodedToken?.sub?.match(/unidentified(.enterpriseCustomerNumber)?:(.*)/);
                if (ecn) {
                    sessionStorage.setItem('ecn', ecn[2]);
                } else if (decodedToken) {
                    sessionStorage.setItem('ecn', decodedToken.ecn);
                    sessionStorage.setItem('UserName', decodedToken.userId);
                    sessionStorage.setItem('guid', decodedToken.guid);
                    sessionStorage.setItem('firstName', decodedToken.given_name);
                    sessionStorage.setItem('email', decodedToken.email);
                }
            }
        }
    }

    private toQueryString(authorizeParams: object): string {
        return `?${Object
            .keys(authorizeParams)
            .map((paramName) => `${paramName}=${authorizeParams[paramName]}`)
            .join('&')}`;
    }
}
