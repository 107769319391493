import { Injectable, Inject } from '@angular/core';
// eslint-disable-next-line sonarjs/deprecation
import { CanActivate } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LoggerService } from '@nationwide/dgs-internet-servicing-policy-common';
import jwtDecode from 'jwt-decode';

@Injectable()
    // eslint-disable-next-line sonarjs/deprecation
export class AuthSuccessInterceptorService implements CanActivate {
    isAuthenticated: boolean = window.sessionStorage.getItem('authenticatedFlag') === 'true';

    constructor(
        @Inject(DOCUMENT) private document: any,
        private logger: LoggerService
    ) { }

    canActivate(): boolean {
        const urlFragment = this.document.location.href;
        const authType = window.sessionStorage.getItem('tokenDetails') ? 'refresh' : 'login';

        if (urlFragment.includes('&') && urlFragment.includes('=')) {
            const tokenFragment = urlFragment.split('#')[1];
            const accessTokenResult = tokenFragment.match(/access_token=(.*?)&/);
            const accessToken = accessTokenResult[1];
            sessionStorage.setItem('access_token', accessToken);
            const idTokenResult = tokenFragment.match(/id_token=(.*?)&/);
            sessionStorage.setItem('id_token', idTokenResult[1]);
            const decodedToken: { sub: string } = jwtDecode(idTokenResult[1]);
            const ecn = RegExp(/unidentified(.enterpriseCustomerNumber)?:(.*)/).exec(decodedToken.sub);
            if (ecn) {
                sessionStorage.setItem('ecn', ecn[2]);
            }
            const expiresInResult = tokenFragment.match(/expires_in=(.*?)&/);
            const expiresIn = expiresInResult[1];
            const millisecondsPerSecond = 1000;
            const tokenTime = Date.now() + expiresIn * millisecondsPerSecond;
            const tokenDetails = `${accessToken},${tokenTime},${expiresIn}`;
            window.sessionStorage.setItem('tokenDetails', tokenDetails);
            parent.postMessage(`${authType}_success`, window.location.origin);
            this.logger.info('AuthSuccessInterceptor success.');
        } else {
            this.logger.error('AuthSuccessInterceptor failed. Oauth params not present.');
        }
        return false;
    }
}
