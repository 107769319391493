import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSharedService } from './shared/app-shared.service';
import { environment } from '../environments/environment';
import { OauthClientService } from './shared/oauth-client.service';
import { LeavingAppService, LoggerService, PageUtilsService, PolicyDataService, PROPERTY_CONSTANTS, WaitModalService } from '@nationwide/dgs-internet-servicing-policy-common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    canLoadApplication = false;
    euaListener: Subscription;
    lastTokenDetails;
    oauthSubscription: Subscription;
    pingRenewalListener: Subscription;
    startingUrl;

    // eslint-disable-next-line max-params
    constructor(
        public router: Router,
        private oauthClientService: OauthClientService,
        private appSharedService: AppSharedService,
        private waitModalService: WaitModalService,
        private LOGGER: LoggerService,
        private policyDataService: PolicyDataService,
        private leavingAppService: LeavingAppService,
        private changeDetectorReference: ChangeDetectorRef,
        private pageUtilsService: PageUtilsService
    ) {
        this.checkForRefreshEvent();
        this.LOGGER.generateUniqueId();
        this.LOGGER.info('App loading...', { startingUrl: this.pageUtilsService.getWindowLocationHref() });
    }

    @HostListener('window:beforeunload', ['$event'])
    leavingApp(): void {
        this.leavingAppService.triggerSelfServiceAbandonment('window unload');
        sessionStorage.setItem('unloadTime', Date.now().toString());
        this.LOGGER.info('Leaving ISP');
        this.LOGGER.logCurrentContents();
        this.changeDetectorReference.detectChanges();
    }

    @HostListener('window:unload', ['$event'])
    onUnload(): void {
        sessionStorage.setItem('unloadTime', Date.now().toString());
    }

    ngOnDestroy(): void {
        if (this.oauthSubscription) {
            this.oauthSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.startingUrl = this.pageUtilsService.getWindowLocationHref();
        this.LOGGER.info('App Initializing...', { startingUrl: this.startingUrl });
        if (this.isOauthRequired()) {
            if (this.appSharedService.isGuidInTheUrl('guid', this.startingUrl)) {
                environment.authorize.auth_method = 'quick-servicing';
                environment.authorize.loginTokenParam = this.appSharedService.getHashParam('guid', this.startingUrl);
                environment.authorize.realm = 'unidentified';
            }
            this.waitModalService.waitMessageUpdate(this.waitModalService.defaultOnLoadMessage, true);
            let ttlValue = PROPERTY_CONSTANTS.TWENTY_MINUTES_IN_SECONDS;
            if (this.startingUrl.includes('ivr')) {
                ttlValue = PROPERTY_CONSTANTS.SIXTY_MINUTES_IN_SECONDS;
            }
            const authorizeOptions = {
                client_id: environment.authorize.client_id,
                nonce: environment.authorize.nonce,
                redirect_uri: `${location.protocol}//${location.host}${environment.authorize.oauthRedirectHandlerPath}`,
                response_type: environment.authorize.responseType,
                scope: environment.authorize.scope,
                state: 'no',
                realm: environment.authorize.realm,
                auth_method: environment.authorize.auth_method,
                ttl: ttlValue,
                ...environment.authorize.auth_method === 'quick-servicing' && { auth_id_guid: environment.authorize.loginTokenParam }
            };
            if (environment.authorize.auth_method === 'quick-servicing') {
                this.callEUA(authorizeOptions);
            } else {
                this.callPingForRenewal(authorizeOptions);
            }
            this.updateTokenDetails(authorizeOptions);
        } else {
            this.canLoadApplication = true;
        }
    }

    private callEUA(authorizeOptions, updateToken = false): void {
        this.euaListener = this.oauthClientService.authorize(authorizeOptions).subscribe((event) => {
            if (event?.authType === 'access') {
                if (event.successful === false) {
                    this.LOGGER.error('Access token generation failed');
                } else {
                    this.LOGGER.info('Access token generated successfully', { token: sessionStorage.getItem('access_token'), idToken: sessionStorage.getItem('id_token'), tokenDetails: sessionStorage.getItem('tokenDetails') });
                    this.copyTokenDetails();
                }
                const token = sessionStorage.getItem('tokenDetails') ? sessionStorage.getItem('tokenDetails').split(',')[0] : '';
                this.policyDataService.accessToken = token;
                if (this.euaListener) {
                    this.euaListener.unsubscribe();
                }
                this.euaComplete(event, updateToken);
            }
        });
    }

    private callPingForRenewal(authorizeOptions): void {
        this.pingRenewalListener = this.oauthClientService.authorize(authorizeOptions, true).subscribe((renewEvent) => {
            if (renewEvent) {
                if (renewEvent.successful === false) {
                    this.LOGGER.error('Renewing Ping Token failed');
                } else {
                    this.LOGGER.info('Renewing Ping Token successful', { token: sessionStorage.getItem('access_token'), idToken: sessionStorage.getItem('id_token'), tokenDetails: sessionStorage.getItem('tokenDetails') });
                }
                if (this.pingRenewalListener) {
                    this.pingRenewalListener.unsubscribe();
                }
                this.callEUA(authorizeOptions, true);
            }
        });
    }

    private checkForRefreshEvent(): void {
        const currentUrl = this.pageUtilsService.getWindowLocationHref();
        const queryParameters = this.pageUtilsService.getQueryParameters(currentUrl);
        const pKey = queryParameters.pkey;
        if (sessionStorage.getItem('unloadTime')) {
            const unloadTime = +sessionStorage.getItem('unloadTime');
            const timeDifference = Date.now() - unloadTime;
            const numberOfSeconds = 55;
            if (timeDifference < PROPERTY_CONSTANTS.MILLISECONDS_IN_SECOND) {
                sessionStorage.setItem('browserRefresh', 'true');
                this.policyDataService.pagesRefreshed.push(location.hash.slice(1));
                this.LOGGER.info('Application refreshed', { pagesRefreshed: this.policyDataService.pagesRefreshed, timeDifference, currentUrl, pKey });
                sessionStorage.removeItem('unloadTime');
            } else if (timeDifference < PROPERTY_CONSTANTS.MILLISECONDS_IN_SECOND * numberOfSeconds) {
                this.LOGGER.info('Navigated back to app after recently leaving', { timeDifference, currentUrl, pKey });
                sessionStorage.removeItem('unloadTime');
            } else {
                this.LOGGER.info('Clearing session storage on load', { timeDifference, currentUrl, pKey });
                sessionStorage.clear();
            }
        } else {
            this.LOGGER.info('Clearing session storage on load when unloadTime is not set', { currentUrl, pKey });
            sessionStorage.clear();
        }
    }

    private copyTokenDetails(): void {
        if (window.sessionStorage.getItem('tokenDetails')) {
            window.sessionStorage.setItem('copyTokenDetails', window.sessionStorage.getItem('tokenDetails'));
        } else if (window.sessionStorage.getItem('tokenDetails') == null &&
            window.sessionStorage.getItem('copyTokenDetails')) {
            window.sessionStorage.setItem('tokenDetails', window.sessionStorage.getItem('copyTokenDetails'));
        }
    }

    private euaComplete(event, updateToken): void {
        this.canLoadApplication = true;
        this.waitModalService.waitMessageUpdate(this.waitModalService.defaultOnLoadMessage, false);
        let tokenType = 'Renewing ping token';
        let logMessage = 'Returned from renewing ping token';
        if (!updateToken) {
            this.policyDataService.timeoutLoggingData.startTimeInMilliseconds = Date.now();
            this.policyDataService.timeoutLoggingData.startTimeDate = new Date().toString();
            tokenType = 'Access token';
            logMessage = 'Returned from Oauth';
        }
        this.policyDataService.timeoutLoggingData.euaResponses.push({ tokenType, successful: event.successful, timeInMilliseconds: Date.now(), timeDate: new Date().toString() });
        this.LOGGER.info(logMessage, { startingUrl: this.startingUrl, event, updateToken, token: window.sessionStorage.getItem('tokenDetails'), copyToken: window.sessionStorage.getItem('copyTokenDetails') });
        this.LOGGER.logCurrentContents();
    }

    private isOauthRequired(): boolean {
        return this.startingUrl && !PROPERTY_CONSTANTS.bypassOauthNavPrefixes.some((prefix) => this.startingUrl.includes(prefix)) && sessionStorage.getItem('bypassExperienceApi') !== 'true';
    }

    private updateTokenDetails(authorizeOptions): void {
        this.policyDataService.renewToken$.subscribe((shouldRenewToken: boolean) => {
            if (shouldRenewToken) {
                this.LOGGER.info('Renewing token details');
                this.callPingForRenewal(authorizeOptions);
            }
        });
    }
}
