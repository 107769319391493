import { Injectable, Inject } from '@angular/core';
// eslint-disable-next-line sonarjs/deprecation
import { CanActivate, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { PROPERTY_CONSTANTS, LoggerService } from '@nationwide/dgs-internet-servicing-policy-common';

@Injectable()
    // eslint-disable-next-line sonarjs/deprecation
export class AuthErrorInterceptorService implements CanActivate {
    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: any,
        private logger: LoggerService
    ) { }

    canActivate(): boolean {
        try {
            const urlFragment = this.document.location.href;
            const errorFragment = urlFragment.split('#')[1];
            const errorArray = errorFragment.split('&');
            const accountErrorCode = errorArray[0].split('=')[1];
            sessionStorage.setItem('authErrorDescription', errorFragment);
            parent.postMessage(accountErrorCode, window.location.origin);
        } catch (error) {
            this.logger.error(error);
        }

        this.router.navigate([`${PROPERTY_CONSTANTS.quickNavPrefix}error-500`]);
        return false;
    }
}
